<template>
  <div class="fab-container">
    <div class="fab-parent fab-icon-holder">
      <i class="fa fa-chevron-up"></i>
    </div>
    <ul class="fab-options">
      <li
        v-if="
          checkPrivilege(
            usersEstablishmentPrivilegeData.usersPrivilegAdditionalSettings
              .additionalSettingsAdd
          )
        "
      >
        <span class="fab-label">
          <router-link
            :to="{
              name: 'GrantsConstraintsPlansSlidDetailsAdd',
              params: {
                grantsConstraintsPlansToken: grantsConstraintsPlansToken,
              },
            }"
          >
            {{ $t("GrantsConstraintsPlansSlidDetails.add") }}
          </router-link>
        </span>
        <div class="fab-icon-holder">
          <router-link
            :to="{
              name: 'GrantsConstraintsPlansSlidDetailsAdd',
              params: {
                grantsConstraintsPlansToken: grantsConstraintsPlansToken,
              },
            }"
          >
            <img src="@/assets/images/plus.svg" />
          </router-link>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import { checkPrivilege } from "@/utils/functions";
import privilegeMixin from "@/utils/privilege-mixin";
import createToastMixin from "@/utils/create-toast-mixin";

export default {
  mixins: [privilegeMixin, createToastMixin],
  props: ["grantsConstraintsPlansToken"],
  data() {
    return {
      userAuthorizeToken:
        this.$store.getters.userData.userPersonalData.userAuthorizeToken,
      language: localStorage.getItem("userLanguage") || "ar",
    };
  },
  components: {},
  methods: {
    checkPrivilege,
  },
  async created() {},
};
</script>
