<template>
  <div v-if="grantsConstraintsPlanData" class="">
    <div class="table-container">
      <table class="my-table">
        <thead>
          <tr>
            <th>{{ $t("GrantsConstraintsPlans.code") }}</th>
            <th>{{ $t("GrantsConstraintsPlans.name") }}</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              {{
                isDataExist(
                  grantsConstraintsPlanData.grantsConstraintsPlansCode
                )
              }}
            </td>
            <td>
              {{
                isDataExist(
                  grantsConstraintsPlanData.grantsConstraintsPlansNameCurrent
                )
              }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { isDataExist } from "@/utils/functions";

export default {
  data() {
    return {
      language: localStorage.getItem("userLanguage") || "ar",
    };
  },
  props: ["grantsConstraintsPlanData"],
  methods: {
    isDataExist,
  },
  computed: {},
  async created() {},
};
</script>
