import PaginationData from "@/models/general/PaginationData";
import GrantsConstraintsPlansSlidDetails from "./GrantsConstraintsPlansSlidDetails";
import GrantsConstraintsPlansSlidesDetailsFilter from "./GrantsConstraintsPlansSlidesDetailsFilter";

export default class GrantsConstraintsPlansSlidesDetails {
  constructor() {
    this.setInitialValue();
  }
  setInitialValue() {
    this.status = 0;
    this.msg = "";
    this.pagination = new PaginationData();
    this.grantsConstraintsPlansSlidesDetailsData = [];
    this.grantsConstraintsPlansSlidDetails =
      new GrantsConstraintsPlansSlidDetails();
    this.filterData = new GrantsConstraintsPlansSlidesDetailsFilter();
  }
  fillData(data) {
    this.status = data.status;
    this.msg = data.msg;
    this.pagination.fillData(
      data.grantsConstraintsPlansSlidesDetailsPagination
    );
    this.grantsConstraintsPlansSlidesDetailsData =
      data.grantsConstraintsPlansSlidesDetailsPagination.grantsConstraintsPlansSlidesDetailsData;
  }
}
