<template>
  <b-modal
    id="GrantsConstraintsPlansSlidDetailsInfo"
    scrollable
    size="lg"
    centered
    hide-footer
  >
    <template #modal-title>
      <h3>
        <img
          src="@/assets/images/GrantsConstraintsPlansSlidesDetails.svg"
          class="icon-lg"
        />
        {{ $t("GrantsConstraintsPlansSlidDetails.data") }}
      </h3>
    </template>
    <div class="row">
      <DataLabelGroup
        :className="'col-lg-12'"
        :value="
          grantsConstraintsPlansSlidDetails.grantsConstraintsPlansNameCurrent
        "
        :title="$t('GrantsConstraintsPlans.name')"
        :imgName="'GrantsConstraintsPlans.svg'"
      />

      <DataLabelGroup
        :className="'col-lg-6'"
        :value="grantsConstraintsPlansSlidDetails.grantTypeNameCurrent"
        :title="$t('employeeGrants.grantType')"
        :imgName="'type.svg'"
      />
      <DataLabelGroup
        :className="'col-lg-6'"
        :value="
          grantsConstraintsPlansSlidDetails.sequentialCalculationOfHourFactorAndValue ==
          true
            ? $t('yes')
            : $t('no')
        "
        :title="
          $t('GrantsConstraintsPlans.sequentialCalculationOfHourFactorAndValue')
        "
        :imgName="'type.svg'"
      />

      <!-- #region MaximumNumber -->
      <DataLabelGroup
        :className="'col-lg-6'"
        :value="
          grantsConstraintsPlansSlidDetails.grantsConstraintsMaximumNumberInDay
        "
        :title="$t('GrantsConstraintsPlans.MaximumNumberInDay')"
        :imgName="'number.svg'"
      />
      <DataLabelGroup
        :className="'col-lg-6'"
        :value="
          grantsConstraintsPlansSlidDetails.grantsConstraintsMaximumNumberInDayActivationStatus ==
          true
            ? $t('yes')
            : $t('no')
        "
        :title="$t('GrantsConstraintsPlans.status')"
        :imgName="'type.svg'"
      />

      <DataLabelGroup
        :className="'col-lg-6'"
        :value="
          grantsConstraintsPlansSlidDetails.grantsConstraintsMaximumNumberInMonth
        "
        :title="$t('GrantsConstraintsPlans.MaximumNumberInMonth')"
        :imgName="'number.svg'"
      />
      <DataLabelGroup
        :className="'col-lg-6'"
        :value="
          grantsConstraintsPlansSlidDetails.grantsConstraintsMaximumNumberInMonthActivationStatus ==
          true
            ? $t('yes')
            : $t('no')
        "
        :title="$t('GrantsConstraintsPlans.status')"
        :imgName="'type.svg'"
      />

      <DataLabelGroup
        :className="'col-lg-6'"
        :value="
          grantsConstraintsPlansSlidDetails.grantsConstraintsMaximumNumberInYear
        "
        :title="$t('GrantsConstraintsPlans.MaximumNumberInYear')"
        :imgName="'number.svg'"
      />
      <DataLabelGroup
        :className="'col-lg-6'"
        :value="
          grantsConstraintsPlansSlidDetails.grantsConstraintsMaximumNumberInYearActivationStatus ==
          true
            ? $t('yes')
            : $t('no')
        "
        :title="$t('GrantsConstraintsPlans.status')"
        :imgName="'type.svg'"
      />
      <!-- #endregion MaximumNumber -->

      <!-- #region MaximumCost -->
      <DataLabelGroup
        :className="'col-lg-6'"
        :value="
          grantsConstraintsPlansSlidDetails.grantsConstraintsMaximumCostInDay
        "
        :title="$t('GrantsConstraintsPlans.MaximumCostInDay')"
        :imgName="'money.svg'"
      />
      <DataLabelGroup
        :className="'col-lg-6'"
        :value="
          grantsConstraintsPlansSlidDetails.grantsConstraintsMaximumCostInDayActivationStatus ==
          true
            ? $t('yes')
            : $t('no')
        "
        :title="$t('GrantsConstraintsPlans.status')"
        :imgName="'type.svg'"
      />

      <DataLabelGroup
        :className="'col-lg-6'"
        :value="
          grantsConstraintsPlansSlidDetails.grantsConstraintsMaximumCostInMonth
        "
        :title="$t('GrantsConstraintsPlans.MaximumCostInMonth')"
        :imgName="'money.svg'"
      />
      <DataLabelGroup
        :className="'col-lg-6'"
        :value="
          grantsConstraintsPlansSlidDetails.grantsConstraintsMaximumCostInMonthActivationStatus ==
          true
            ? $t('yes')
            : $t('no')
        "
        :title="$t('GrantsConstraintsPlans.status')"
        :imgName="'type.svg'"
      />

      <DataLabelGroup
        :className="'col-lg-6'"
        :value="
          grantsConstraintsPlansSlidDetails.grantsConstraintsMaximumCostInYear
        "
        :title="$t('GrantsConstraintsPlans.MaximumCostInYear')"
        :imgName="'money.svg'"
      />
      <DataLabelGroup
        :className="'col-lg-6'"
        :value="
          grantsConstraintsPlansSlidDetails.grantsConstraintsMaximumCostInYearActivationStatus ==
          true
            ? $t('yes')
            : $t('no')
        "
        :title="$t('GrantsConstraintsPlans.status')"
        :imgName="'type.svg'"
      />
      <!-- #endregion MaximumCost -->

      <!-- #region MaximumHourFactor -->
      <DataLabelGroup
        :className="'col-lg-6'"
        :value="
          grantsConstraintsPlansSlidDetails.grantsConstraintsMaximumHourFactorInDay
        "
        :title="$t('GrantsConstraintsPlans.MaximumHourFactorInDay')"
        :imgName="'number.svg'"
      />
      <DataLabelGroup
        :className="'col-lg-6'"
        :value="
          grantsConstraintsPlansSlidDetails.grantsConstraintsMaximumHourFactorInDayActivationStatus ==
          true
            ? $t('yes')
            : $t('no')
        "
        :title="$t('GrantsConstraintsPlans.status')"
        :imgName="'type.svg'"
      />

      <DataLabelGroup
        :className="'col-lg-6'"
        :value="
          grantsConstraintsPlansSlidDetails.grantsConstraintsMaximumHourFactorInMonth
        "
        :title="$t('GrantsConstraintsPlans.MaximumHourFactorInMonth')"
        :imgName="'number.svg'"
      />
      <DataLabelGroup
        :className="'col-lg-6'"
        :value="
          grantsConstraintsPlansSlidDetails.grantsConstraintsMaximumHourFactorInMonthActivationStatus ==
          true
            ? $t('yes')
            : $t('no')
        "
        :title="$t('GrantsConstraintsPlans.status')"
        :imgName="'type.svg'"
      />

      <DataLabelGroup
        :className="'col-lg-6'"
        :value="
          grantsConstraintsPlansSlidDetails.grantsConstraintsMaximumHourFactorInYear
        "
        :title="$t('GrantsConstraintsPlans.MaximumHourFactorInYear')"
        :imgName="'number.svg'"
      />
      <DataLabelGroup
        :className="'col-lg-6'"
        :value="
          grantsConstraintsPlansSlidDetails.grantsConstraintsMaximumHourFactorInYearActivationStatus ==
          true
            ? $t('yes')
            : $t('no')
        "
        :title="$t('GrantsConstraintsPlans.status')"
        :imgName="'type.svg'"
      />
      <!-- #endregion MaximumHourFactor -->

      <DataLabelGroup
        :className="'col-lg-12'"
        :value="
          grantsConstraintsPlansSlidDetails.grantsConstraintsPlansSlidesDetailsNote
        "
        :title="$t('notes')"
        :imgName="'notes.svg'"
      />
    </div>
  </b-modal>
</template>

<script>
import DataLabelGroup from "@/components/general/DataLabelGroup.vue";

export default {
  components: {
    DataLabelGroup,
  },
  data() {
    return {};
  },
  props: ["grantsConstraintsPlansSlidDetails"],
  methods: {},
};
</script>
