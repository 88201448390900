var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"id":"GrantsConstraintsPlansSlidDetailsInfo","scrollable":"","size":"lg","centered":"","hide-footer":""},scopedSlots:_vm._u([{key:"modal-title",fn:function(){return [_c('h3',[_c('img',{staticClass:"icon-lg",attrs:{"src":require("@/assets/images/GrantsConstraintsPlansSlidesDetails.svg")}}),_vm._v(" "+_vm._s(_vm.$t("GrantsConstraintsPlansSlidDetails.data"))+" ")])]},proxy:true}])},[_c('div',{staticClass:"row"},[_c('DataLabelGroup',{attrs:{"className":'col-lg-12',"value":_vm.grantsConstraintsPlansSlidDetails.grantsConstraintsPlansNameCurrent,"title":_vm.$t('GrantsConstraintsPlans.name'),"imgName":'GrantsConstraintsPlans.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-lg-6',"value":_vm.grantsConstraintsPlansSlidDetails.grantTypeNameCurrent,"title":_vm.$t('employeeGrants.grantType'),"imgName":'type.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-lg-6',"value":_vm.grantsConstraintsPlansSlidDetails.sequentialCalculationOfHourFactorAndValue ==
        true
          ? _vm.$t('yes')
          : _vm.$t('no'),"title":_vm.$t('GrantsConstraintsPlans.sequentialCalculationOfHourFactorAndValue'),"imgName":'type.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-lg-6',"value":_vm.grantsConstraintsPlansSlidDetails.grantsConstraintsMaximumNumberInDay,"title":_vm.$t('GrantsConstraintsPlans.MaximumNumberInDay'),"imgName":'number.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-lg-6',"value":_vm.grantsConstraintsPlansSlidDetails.grantsConstraintsMaximumNumberInDayActivationStatus ==
        true
          ? _vm.$t('yes')
          : _vm.$t('no'),"title":_vm.$t('GrantsConstraintsPlans.status'),"imgName":'type.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-lg-6',"value":_vm.grantsConstraintsPlansSlidDetails.grantsConstraintsMaximumNumberInMonth,"title":_vm.$t('GrantsConstraintsPlans.MaximumNumberInMonth'),"imgName":'number.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-lg-6',"value":_vm.grantsConstraintsPlansSlidDetails.grantsConstraintsMaximumNumberInMonthActivationStatus ==
        true
          ? _vm.$t('yes')
          : _vm.$t('no'),"title":_vm.$t('GrantsConstraintsPlans.status'),"imgName":'type.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-lg-6',"value":_vm.grantsConstraintsPlansSlidDetails.grantsConstraintsMaximumNumberInYear,"title":_vm.$t('GrantsConstraintsPlans.MaximumNumberInYear'),"imgName":'number.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-lg-6',"value":_vm.grantsConstraintsPlansSlidDetails.grantsConstraintsMaximumNumberInYearActivationStatus ==
        true
          ? _vm.$t('yes')
          : _vm.$t('no'),"title":_vm.$t('GrantsConstraintsPlans.status'),"imgName":'type.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-lg-6',"value":_vm.grantsConstraintsPlansSlidDetails.grantsConstraintsMaximumCostInDay,"title":_vm.$t('GrantsConstraintsPlans.MaximumCostInDay'),"imgName":'money.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-lg-6',"value":_vm.grantsConstraintsPlansSlidDetails.grantsConstraintsMaximumCostInDayActivationStatus ==
        true
          ? _vm.$t('yes')
          : _vm.$t('no'),"title":_vm.$t('GrantsConstraintsPlans.status'),"imgName":'type.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-lg-6',"value":_vm.grantsConstraintsPlansSlidDetails.grantsConstraintsMaximumCostInMonth,"title":_vm.$t('GrantsConstraintsPlans.MaximumCostInMonth'),"imgName":'money.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-lg-6',"value":_vm.grantsConstraintsPlansSlidDetails.grantsConstraintsMaximumCostInMonthActivationStatus ==
        true
          ? _vm.$t('yes')
          : _vm.$t('no'),"title":_vm.$t('GrantsConstraintsPlans.status'),"imgName":'type.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-lg-6',"value":_vm.grantsConstraintsPlansSlidDetails.grantsConstraintsMaximumCostInYear,"title":_vm.$t('GrantsConstraintsPlans.MaximumCostInYear'),"imgName":'money.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-lg-6',"value":_vm.grantsConstraintsPlansSlidDetails.grantsConstraintsMaximumCostInYearActivationStatus ==
        true
          ? _vm.$t('yes')
          : _vm.$t('no'),"title":_vm.$t('GrantsConstraintsPlans.status'),"imgName":'type.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-lg-6',"value":_vm.grantsConstraintsPlansSlidDetails.grantsConstraintsMaximumHourFactorInDay,"title":_vm.$t('GrantsConstraintsPlans.MaximumHourFactorInDay'),"imgName":'number.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-lg-6',"value":_vm.grantsConstraintsPlansSlidDetails.grantsConstraintsMaximumHourFactorInDayActivationStatus ==
        true
          ? _vm.$t('yes')
          : _vm.$t('no'),"title":_vm.$t('GrantsConstraintsPlans.status'),"imgName":'type.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-lg-6',"value":_vm.grantsConstraintsPlansSlidDetails.grantsConstraintsMaximumHourFactorInMonth,"title":_vm.$t('GrantsConstraintsPlans.MaximumHourFactorInMonth'),"imgName":'number.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-lg-6',"value":_vm.grantsConstraintsPlansSlidDetails.grantsConstraintsMaximumHourFactorInMonthActivationStatus ==
        true
          ? _vm.$t('yes')
          : _vm.$t('no'),"title":_vm.$t('GrantsConstraintsPlans.status'),"imgName":'type.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-lg-6',"value":_vm.grantsConstraintsPlansSlidDetails.grantsConstraintsMaximumHourFactorInYear,"title":_vm.$t('GrantsConstraintsPlans.MaximumHourFactorInYear'),"imgName":'number.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-lg-6',"value":_vm.grantsConstraintsPlansSlidDetails.grantsConstraintsMaximumHourFactorInYearActivationStatus ==
        true
          ? _vm.$t('yes')
          : _vm.$t('no'),"title":_vm.$t('GrantsConstraintsPlans.status'),"imgName":'type.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-lg-12',"value":_vm.grantsConstraintsPlansSlidDetails.grantsConstraintsPlansSlidesDetailsNote,"title":_vm.$t('notes'),"imgName":'notes.svg'}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }