<template>
  <div class="">
    <PreLoader v-if="isLoading" />
    <ExceptionWithImg
      v-if="exceptionMsg"
      :msg="exceptionMsg"
      :image="exceptionImg"
    />

    <div
      v-if="
        grantsConstraintsPlansSlidesDetails.grantsConstraintsPlansSlidesDetailsData !=
          undefined &&
        grantsConstraintsPlansSlidesDetails
          .grantsConstraintsPlansSlidesDetailsData.length != 0
      "
    >
      <GrantsConstraintsPlansNameTable
        :grantsConstraintsPlanData="
          grantsConstraintsPlansSlidesDetails
            .grantsConstraintsPlansSlidesDetailsData[0]
        "
      />

      <GrantsConstraintsPlansSlidesDetailsTable
        v-on:setGrantsConstraintsPlanSlidDetails="
          grantsConstraintsPlansSlidesDetails.grantsConstraintsPlansSlidDetails.fillData(
            $event
          )
        "
        :grantsConstraintsPlansSlidesDetailsData="
          grantsConstraintsPlansSlidesDetails.grantsConstraintsPlansSlidesDetailsData
        "
        :pagination="grantsConstraintsPlansSlidesDetails.pagination"
      />

      <Pagination
        v-if="!isLoading"
        :paginationData="grantsConstraintsPlansSlidesDetails.pagination"
        v-on:changePage="changePage"
      />
      <GrantsConstraintsPlansSlidDetailsInfo
        :grantsConstraintsPlansSlidDetails="
          grantsConstraintsPlansSlidesDetails.grantsConstraintsPlansSlidDetails
        "
      />
      <GrantsConstraintsPlansSlidDetailsDelete
        :grantsConstraintsPlansSlidDetails="
          grantsConstraintsPlansSlidesDetails.grantsConstraintsPlansSlidDetails
        "
        v-on:refresh="getAllGrantsConstraintsPlansSlidesDetails()"
      />
    </div>

    <GrantsConstraintsPlansSlidDetailsBtns
      :grantsConstraintsPlansToken="grantsConstraintsPlansToken"
    />
  </div>
</template>

<script>
import PreLoader from "@/components/general/PreLoader.vue";
import ExceptionWithImg from "@/components/general/ExceptionWithImg.vue";
import { STATUS } from "@/utils/constants";
import GrantsConstraintsPlansNameTable from "@/components/settings/settingsSalary/grantsConstraintsPlans/GrantsConstraintsPlansNameTable.vue";
import GrantsConstraintsPlansSlidesDetailsTable from "@/components/settings/settingsSalary/grantsConstraintsPlansSlidesDetails/GrantsConstraintsPlansSlidesDetailsTable.vue";
import GrantsConstraintsPlansSlidDetailsInfo from "@/components/settings/settingsSalary/grantsConstraintsPlansSlidesDetails/GrantsConstraintsPlansSlidDetailsInfo.vue";
import GrantsConstraintsPlansSlidDetailsDelete from "@/components/settings/settingsSalary/grantsConstraintsPlansSlidesDetails/GrantsConstraintsPlansSlidDetailsDelete.vue";
import GrantsConstraintsPlansSlidDetailsBtns from "@/components/settings/settingsSalary/grantsConstraintsPlansSlidesDetails/GrantsConstraintsPlansSlidDetailsBtns.vue";
import Pagination from "@/components/general/Pagination.vue";
import GrantsConstraintsPlansSlidesDetails from "@/models/settings/settingsSalary/grantsConstraintsPlansSlidesDetails/GrantsConstraintsPlansSlidesDetails";
import createToastMixin from "@/utils/create-toast-mixin";

export default {
  mixins: [createToastMixin],
  components: {
    PreLoader,
    ExceptionWithImg,
    GrantsConstraintsPlansNameTable,
    GrantsConstraintsPlansSlidesDetailsTable,
    GrantsConstraintsPlansSlidDetailsInfo,
    GrantsConstraintsPlansSlidDetailsDelete,
    GrantsConstraintsPlansSlidDetailsBtns,
    Pagination,
  },
  data() {
    return {
      isLoading: true,
      exceptionMsg: null,
      exceptionImg: null,
      userAuthorizeToken:
        this.$store.getters.userData.userPersonalData.userAuthorizeToken,
      language: localStorage.getItem("userLanguage") || "ar",
      grantsConstraintsPlansSlidesDetails:
        new GrantsConstraintsPlansSlidesDetails(),
      grantsConstraintsPlansToken:
        this.$route.params.grantsConstraintsPlansToken,
    };
  },
  methods: {
    changePage(page) {
      this.grantsConstraintsPlansSlidesDetails.pagination.selfPage = page;
      this.getAllGrantsConstraintsPlansSlidesDetails();
    },
    async getAllGrantsConstraintsPlansSlidesDetails() {
      this.isLoading = true;
      this.grantsConstraintsPlansSlidesDetails.filterData.grantsConstraintsPlansToken =
        this.grantsConstraintsPlansToken;
      if (
        this.grantsConstraintsPlansSlidesDetails.filterData
          .grantsConstraintsPlansToken == "" ||
        this.grantsConstraintsPlansSlidesDetails.filterData
          .grantsConstraintsPlansToken == undefined
      ) {
        this.exceptionImg = "illustrator-somethingWrong.svg";
        this.exceptionMsg = this.$t("errorCatch");
        this.$router.push({ name: "GrantsConstraintsPlans" }).catch(() => {});
        this.isLoading = false;
        return;
      }

      try {
        const response =
          await this.grantsConstraintsPlansSlidesDetails.grantsConstraintsPlansSlidDetails.getAllGrantsConstraintsPlansSlidesDetails(
            this.language,
            this.userAuthorizeToken,
            this.grantsConstraintsPlansSlidesDetails.pagination,
            this.grantsConstraintsPlansSlidesDetails.filterData
          );
        if (response.data.status == STATUS.SUCCESS) {
          this.exceptionMsg = null;
          this.grantsConstraintsPlansSlidesDetails.fillData(response.data);
        } else if (response.data.status == STATUS.NO_CONTENT) {
          this.grantsConstraintsPlansSlidesDetails.grantsConstraintsPlansSlidesDetailsData =
            [];
          this.exceptionImg = "";
          this.exceptionMsg = response.data.msg;
        } else if (response.data.status == STATUS.INVALID_TOKEN) {
          this.grantsConstraintsPlansSlidesDetails.grantsConstraintsPlansSlidesDetailsData =
            [];
          this.$store.dispatch("logoutUser");
          this.$router.push("/").catch(() => {});
        } else {
          this.grantsConstraintsPlansSlidesDetails.grantsConstraintsPlansSlidesDetailsData =
            [];
          this.exceptionImg = "";
          this.exceptionMsg = response.data.msg;
        }
      } catch (error) {
        this.grantsConstraintsPlansSlidesDetails.grantsConstraintsPlansSlidesDetailsData =
          [];
        this.exceptionImg = "illustrator-somethingWrong.svg";
        this.exceptionMsg = this.$t("errorCatch");
      }
      this.isLoading = false;
    },
  },
  computed: {},
  async created() {
    this.getAllGrantsConstraintsPlansSlidesDetails();
  },
};
</script>
