<template>
  <div v-if="grantsConstraintsPlansSlidesDetailsData" class="">
    <div class="table-container">
      <table class="my-table">
        <thead>
          <tr>
            <th>#</th>
            <th>{{ $t("employeeGrants.grantType") }}</th>
            <th>{{ $t("GrantsConstraintsPlans.MaximumNumberInDay") }}</th>
            <th>{{ $t("GrantsConstraintsPlans.MaximumCostInDay") }}</th>
            <th>{{ $t("GrantsConstraintsPlans.MaximumHourFactorInDay") }}</th>
            <th>{{ $t("info") }}</th>
            <th
              v-if="
                checkPrivilege(
                  usersEstablishmentPrivilegeData
                    .usersPrivilegAdditionalSettings.additionalSettingsUpdate
                )
              "
            >
              {{ $t("edit") }}
            </th>
            <th
              v-if="
                checkPrivilege(
                  usersEstablishmentPrivilegeData
                    .usersPrivilegAdditionalSettings.additionalSettingsArchive
                )
              "
            >
              {{ $t("delete") }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(Slid, index) in grantsConstraintsPlansSlidesDetailsData"
            :key="index"
          >
            <td>{{ ++index + pagination.currentIndex }}</td>
            <td>{{ isDataExist(Slid.grantTypeNameCurrent) }}</td>
            <td>{{ isDataExist(Slid.grantsConstraintsMaximumNumberInDay) }}</td>
            <td>
              {{ isDataExist(Slid.grantsConstraintsMaximumCostInDay) }}
            </td>
            <td>
              {{ isDataExist(Slid.grantsConstraintsMaximumHourFactorInDay) }}
            </td>
            <td>
              <button
                v-b-modal.GrantsConstraintsPlansSlidDetailsInfo
                class="btn p-0"
                :title="$t('info')"
                @click="setGrantsConstraintsPlanSlidDetails(Slid)"
              >
                <img src="@/assets/images/info.svg" class="icon-lg" />
              </button>
            </td>
            <td
              v-if="
                checkPrivilege(
                  usersEstablishmentPrivilegeData
                    .usersPrivilegAdditionalSettings.additionalSettingsUpdate
                )
              "
            >
              <router-link
                :to="{
                  name: 'GrantsConstraintsPlansSlidDetailsEdit',
                  params: {
                    grantsConstraintsPlansSlidesDetailsToken:
                      Slid.grantsConstraintsPlansSlidesDetailsToken,
                  },
                }"
                :title="$t('edit')"
              >
                <img src="@/assets/images/pencil.svg" class="icon-lg" />
              </router-link>
            </td>
            <td
              v-if="
                checkPrivilege(
                  usersEstablishmentPrivilegeData
                    .usersPrivilegAdditionalSettings.additionalSettingsArchive
                )
              "
            >
              <button
                v-b-modal.GrantsConstraintsPlansSlidDetailsDelete
                class="btn p-0"
                :title="$t('delete')"
                @click="setGrantsConstraintsPlanSlidDetails(Slid)"
              >
                <img src="@/assets/images/trash.svg" class="icon-lg" />
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import privilegeMixin from "@/utils/privilege-mixin";
import { formateDateTimeLang, isDataExist } from "@/utils/functions";

export default {
  mixins: [privilegeMixin],
  data() {
    return {
      isLoading: true,
      exceptionMsg: null,
      exceptionImg: null,
      language: localStorage.getItem("userLanguage") || "ar",
      userAuthorizeToken:
        this.$store.getters.userData.userPersonalData.userAuthorizeToken ||
        null,
    };
  },
  props: ["grantsConstraintsPlansSlidesDetailsData", "pagination"],
  methods: {
    setGrantsConstraintsPlanSlidDetails(data) {
      this.$emit("setGrantsConstraintsPlanSlidDetails", data);
    },
    formateDateTimeLang(date, time) {
      return formateDateTimeLang(date, time, this.language);
    },
    isDataExist,
  },
  computed: {},
  async created() {},
};
</script>
