var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"fab-container"},[_vm._m(0),_c('ul',{staticClass:"fab-options"},[(
        _vm.checkPrivilege(
          _vm.usersEstablishmentPrivilegeData.usersPrivilegAdditionalSettings
            .additionalSettingsAdd
        )
      )?_c('li',[_c('span',{staticClass:"fab-label"},[_c('router-link',{attrs:{"to":{
            name: 'GrantsConstraintsPlansSlidDetailsAdd',
            params: {
              grantsConstraintsPlansToken: _vm.grantsConstraintsPlansToken,
            },
          }}},[_vm._v(" "+_vm._s(_vm.$t("GrantsConstraintsPlansSlidDetails.add"))+" ")])],1),_c('div',{staticClass:"fab-icon-holder"},[_c('router-link',{attrs:{"to":{
            name: 'GrantsConstraintsPlansSlidDetailsAdd',
            params: {
              grantsConstraintsPlansToken: _vm.grantsConstraintsPlansToken,
            },
          }}},[_c('img',{attrs:{"src":require("@/assets/images/plus.svg")}})])],1)]):_vm._e()])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"fab-parent fab-icon-holder"},[_c('i',{staticClass:"fa fa-chevron-up"})])}]

export { render, staticRenderFns }