var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{},[(_vm.isLoading)?_c('PreLoader'):_vm._e(),(_vm.exceptionMsg)?_c('ExceptionWithImg',{attrs:{"msg":_vm.exceptionMsg,"image":_vm.exceptionImg}}):_vm._e(),(
      _vm.grantsConstraintsPlansSlidesDetails.grantsConstraintsPlansSlidesDetailsData !=
        undefined &&
      _vm.grantsConstraintsPlansSlidesDetails
        .grantsConstraintsPlansSlidesDetailsData.length != 0
    )?_c('div',[_c('GrantsConstraintsPlansNameTable',{attrs:{"grantsConstraintsPlanData":_vm.grantsConstraintsPlansSlidesDetails
          .grantsConstraintsPlansSlidesDetailsData[0]}}),_c('GrantsConstraintsPlansSlidesDetailsTable',{attrs:{"grantsConstraintsPlansSlidesDetailsData":_vm.grantsConstraintsPlansSlidesDetails.grantsConstraintsPlansSlidesDetailsData,"pagination":_vm.grantsConstraintsPlansSlidesDetails.pagination},on:{"setGrantsConstraintsPlanSlidDetails":function($event){return _vm.grantsConstraintsPlansSlidesDetails.grantsConstraintsPlansSlidDetails.fillData(
          $event
        )}}}),(!_vm.isLoading)?_c('Pagination',{attrs:{"paginationData":_vm.grantsConstraintsPlansSlidesDetails.pagination},on:{"changePage":_vm.changePage}}):_vm._e(),_c('GrantsConstraintsPlansSlidDetailsInfo',{attrs:{"grantsConstraintsPlansSlidDetails":_vm.grantsConstraintsPlansSlidesDetails.grantsConstraintsPlansSlidDetails}}),_c('GrantsConstraintsPlansSlidDetailsDelete',{attrs:{"grantsConstraintsPlansSlidDetails":_vm.grantsConstraintsPlansSlidesDetails.grantsConstraintsPlansSlidDetails},on:{"refresh":function($event){return _vm.getAllGrantsConstraintsPlansSlidesDetails()}}})],1):_vm._e(),_c('GrantsConstraintsPlansSlidDetailsBtns',{attrs:{"grantsConstraintsPlansToken":_vm.grantsConstraintsPlansToken}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }